import Cookies from 'js-cookie'
import DashCore from 'dash-js'

const DashInstance = new DashCore({
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
})
const envAcronym = DashInstance.envAcronym
const nameCookie = `${envAcronym}mp_time`

export const getCookieTimeline = () => {
  try {
    const cookieData = Cookies.get(nameCookie)
    return cookieData ? JSON.parse(atob(cookieData)) : {}
  } catch (error) {
    Cookies.remove(nameCookie)
    console.error('Error getting cookie timeline')
    return {}
  }
}

export const setCookieTimeline = (data = {}) => {
  try {
    const cookieData = getCookieTimeline()
    const updatedData = { ...cookieData, ...data }

    Cookies.set(nameCookie, btoa(JSON.stringify(updatedData)), { expires: 90 })
  } catch (error) {
    console.error('Error setting cookie timeline')
  }
}

export const setCookieTimelineWithAccountId = (accountId, data) => {
  const cookieData = {}
  cookieData[accountId] = data
  setCookieTimeline(cookieData)
}

export const getCookieTimelineByAccountId = accountId => {
  const cookieData = getCookieTimeline()
  return cookieData && cookieData[accountId] ? cookieData[accountId] : {}
}
