import { isNotFound } from '@/enums/http-status.enum'
import { CHARGEBACK_STATUS_ENUM } from '@/enums/chargeback.enum'
import { DashInstance, catchError } from '@/store'
import { HTTP_STATUS_CODE } from '@enums/http-status.enum'

const chargeback = {
  state: {
    chargeback: null,
    chargebacks: [],
    dueChargebacks: [],
    loadingChargebacks: false,
    loadingChargeback: false,
    loadingDueChargebacks: false
  },
  getters: {
    loadingChargebacks (state) {
      return state.loadingChargebacks
    },
    loadingDueChargebacks (state) {
      return state.loadingDueChargebacks
    },
    loadingChargeback (state) {
      return state.loadingChargeback
    },
    chargebacks (state) {
      return state.chargebacks
    },
    dueChargebacks (state) {
      return state.dueChargebacks
    },
    chargeback (state) {
      return state.chargeback
    },
    isListEmpty (state) {
      return state.chargebacks.length === 0
    }
  },
  mutations: {
    SET_CHARGEBACKS (state, chargebacks) {
      state.chargebacks = chargebacks
    },
    SET_CHARGEBACK (state, chargeback) {
      state.chargeback = chargeback
    },
    SET_LOADING_CHARGEBACKS (state, loading) {
      state.loadingChargebacks = loading
    },
    SET_LOADING_CHARGEBACK (state, loading) {
      state.loadingChargeback = loading
    },
    SET_LOADING_DUE_CHARGEBACKS (state, loading) {
      state.loadingDueChargebacks = loading
    },
    SET_DUE_CHARGEBACKS (state, chargebacks) {
      state.dueChargebacks = chargebacks
    }
  },
  actions: {
    GET_ALL_CHARGEBACKS (context, params = {}) {
      context.commit('SET_LOADING_CHARGEBACKS', true)
      return DashInstance.chargeback
        .getAll(context.getters.merchantId, context.getters.accountId, params)
        .then(res => {
          context.commit('SET_CHARGEBACKS', res)
          return Promise.resolve(res)
        })
        .catch(err => {
          if (isNotFound(err.status)) {
            const empty = { data: [], pagination: {} }
            context.commit('SET_CHARGEBACKS', empty)

            return Promise.resolve(empty)
          }
          catchError(err, context)
        })
        .finally(() => {
          context.commit('SET_LOADING_CHARGEBACKS', false)
        })
    },
    GET_ALL_DUE_CHARGEBACKS (context, params = {}) {
      context.commit('SET_LOADING_DUE_CHARGEBACKS', true)
      return DashInstance.chargeback
        .getAll(context.getters.merchantId, context.getters.accountId, {
          ...params,
          status: CHARGEBACK_STATUS_ENUM.WAITING_MERCHANT_EVIDENCES.key
        })
        .then(res => {
          context.commit('SET_DUE_CHARGEBACKS', res)
          return Promise.resolve(res)
        })
        .catch(err => {
          if (isNotFound(err.status)) {
            const empty = { data: [], pagination: {} }
            context.commit('SET_DUE_CHARGEBACKS', empty)

            return Promise.resolve(empty)
          }
          catchError(err, context)
        })
        .finally(() => {
          context.commit('SET_LOADING_DUE_CHARGEBACKS', false)
        })
    },
    GET_ONE_CHARGEBACK (context, params) {
      context.commit('SET_LOADING_CHARGEBACK', true)
      return DashInstance.chargeback
        .getById(
          context.getters.merchantId,
          context.getters.accountId,
          params.chargebackId
        )
        .then(res => {
          context.commit('SET_CHARGEBACK', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => catchError(err, context))
        .finally(() => {
          context.commit('SET_LOADING_CHARGEBACK', false)
        })
    },
    VALIDATE_FILE (context, params = {}) {
      return DashInstance.chargeback
        .createValidateRepresentment(
          context.getters.merchantId,
          context.getters.accountId,
          params.transactionId,
          params.files
        )
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          if (err.status === 422) {
            return Promise.resolve(err.body)
          }
          return catchError(err, context)
        })
    },
    VALIDATE_FILES_BATCH (context, params = {}) {
      return DashInstance.chargeback
        .createValidateRepresentmentBatch(
          context.getters.merchantId,
          context.getters.accountId,
          params.files
        )
        .then(Promise.resolve)
        .catch(err => {
          if (err.status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY) {
            return Promise.resolve(err.body)
          }
          return catchError(err, context)
        })
    },
    PROCESS_FILE (context, params) {
      return DashInstance.chargeback
        .processRepresentment(
          context.getters.merchantId,
          context.getters.accountId,
          params.transactionId,
          params.files
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    PROCESS_FILE_BATCH (context, params) {
      return DashInstance.chargeback
        .processRepresentmentBatch(
          context.getters.merchantId,
          context.getters.accountId,
          params.files
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    EXPORT_CHARGEBACK_CSV (context) {
      return DashInstance.chargeback
        .exportCsv(context.getters.merchantId, context.getters.accountId)
        .then(res => Promise.resolve(res))
        .catch(Promise.reject)
        .finally(() => {
          context.commit('SET_LOADING_CHARGEBACK', false)
        })
    }
  }
}

export default chargeback
