const ACCOUNT_TYPES_ENUM = {
  TEST: 'test',
  PRODUCTION: 'production'
}

const isTestAccount = accountType => {
  return accountType === ACCOUNT_TYPES_ENUM.TEST
}

const isProductionAccount = accountType => {
  return accountType === ACCOUNT_TYPES_ENUM.PRODUCTION
}

export { ACCOUNT_TYPES_ENUM, isTestAccount, isProductionAccount }
