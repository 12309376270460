import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
const utc = require('dayjs/plugin/utc')
require('dayjs/locale/pt')

dayjs.extend(localizedFormat)
dayjs.extend(utc)

const formats = {
  date: 'L',
  dateAndHour: 'L - LTS'
}

export function setDateLocale (locale) {
  if (locale) {
    dayjs.locale(locale.substring(0, 2))
  }
}

export function dateFormat (date, format = 'date') {
  if (!date) {
    return '-'
  }

  return dayjs.utc(date).format(formats[format])
}

export function statusClass (_status) {
  const status = _status?.toLowerCase()

  const STATUS_MAP = {
    success: [
      'finalizado',
      'finalized',
      'paid',
      'pago',
      'overpaid',
      'paga a maior',
      'underpaid',
      'paga a menor',
      'captured',
      'capturado',
      'sent',
      'enviado',
      'approved',
      'aprovado',
      'parcialmente capturado',
      'partial capture',
      'none',
      'accepted',
      'aceito',
      'paga',
      'to receive',
      'installed',
      'concluído',
      'download',
      'baixada'
    ],
    warning: [
      'order.status.new',
      'future',
      'futuro',
      'pending',
      'pendente',
      'processing',
      'processando',
      'partial refunded',
      'parcialmente estornado',
      'parcialmente cancelado',
      'partial void',
      'generated',
      'gerado',
      'manual',
      'authorized pending capture',
      'autorizado captura pendente',
      'cartão de crédito pendente',
      'pending credit card',
      'aguardando cancelamento',
      'waiting cancellation',
      'aguardando captura',
      'waiting capture',
      'major',
      'pending authentication',
      'pendente de autenticação',
      'registration',
      'em registro',
      'in registration',
      'affiliation',
      'em afiliação',
      'in affiliation',
      'suspended',
      'suspenso',
      'suspended',
      'resgistro',
      'registration',
      'afiliação',
      'affiliation',
      'a receber',
      'to recive',
      'received',
      'infield',
      'new',
      'nova',
      'inreview',
      'intransfer',
      'inexpedition',
      'em transferência',
      'em expedição',
      'em campo',
      'em tratamento n1',
      'em tratamento n2',
      'anormalidade',
      'pré-baixada',
      'pre-cancelada',
      'repique',
      'em análise',
      'em andamento',
      'received',
      'recebida',
      'recebido',
      'pendente de análise'
    ],
    danger: [
      'canceled',
      'cancelado',
      'estornado',
      'refunded',
      'inactive',
      'inativo',
      'failed',
      'falha',
      'deleted',
      'deletado',
      'with error',
      'com erro',
      'not paid',
      'não pago',
      'expired',
      'expirado',
      'not authorized',
      'não autorizado',
      'voided',
      'negado',
      'erro no cancelamento',
      'error on voiding',
      'erro no estorno',
      'error on refunding',
      'critical',
      'refused',
      'recusado',
      'blocked',
      'bloqueado',
      'uninstalled',
      'realocada',
      'reallocated',
      'cancelada',
      'reprovado',
      'reproved'
    ],
    info: [
      'active',
      'ativo',
      'gerado',
      'generated',
      'minor',
      'maintenance',
      'waiting refund',
      'aguardando estorno',
      'waiting payment',
      'aguardando pagamento'
    ],
    black: ['chargeback', 'chargeback reapresentado', 'chargeback refund'],
    secondary: [
      'estorno',
      'estorno parcial',
      'estornada',
      'refunded',
      'partial refund'
    ]
  }

  return {
    _color_success: STATUS_MAP.success.includes(status),
    _color_positive: STATUS_MAP.success.includes(status),
    _color_warning: STATUS_MAP.warning.includes(status),
    _color_danger: STATUS_MAP.danger.includes(status),
    _color_negative: STATUS_MAP.danger.includes(status),
    _color_info: STATUS_MAP.info.includes(status),
    _color_black: STATUS_MAP.black.includes(status),
    _color_secondary: STATUS_MAP.secondary.includes(status),
    _color_neutral: STATUS_MAP.secondary.includes(status)
  }
}

export function pluralIntervals (intervalCount, interval) {
  interval = interval?.toLowerCase()
  if (intervalCount > 1) {
    const plurals = [
      {
        dia: 'dias',
        day: 'days',
        semana: 'semanas',
        week: 'weeks',
        mês: 'meses',
        month: 'months',
        ano: 'anos',
        year: 'years'
      }
    ]
    const plural = plurals.find(e => e[interval])
    return plural[interval]
  } else {
    return interval
  }
}

export function formatCurrency (value) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
}

export function currencyToNumber (value) {
  if (!value) return 0
  if (typeof value !== 'string') return value
  if (!value.includes('R$'))
    throw new Error(`Value provided ${value} is not a valid currency!`)
  value = value.replace(' ', '')
  const mapObj = {
    R$: '',
    '.': '',
    ',': '.'
  }
  return Number(+value.replace(/R\$|\.|,/gi, matched => mapObj[matched]))
}

export function percentageToNumber (value) {
  if (!value) return 0
  if (typeof value !== 'string') return value / 100
  if (!value.includes('%'))
    throw new Error(`Value provided ${value} is not a percentage!`)

  const number = +replaceAll(value, {
    ',': '.',
    '%': ''
  })

  return Number(number) / 100
}

export function replaceAll (str, mapObj) {
  if (typeof str !== 'string') return str
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi')
  return str.replace(re, matched => mapObj[matched.toLowerCase()])
}
