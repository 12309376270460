import routeHome from './home.route'
import routeRecipients from './recipients.route'
import routeCustomer from './customer.route'
import routeFinancial from './financial.route'
import routeContracts from './contracts.route'
import routeReceivablesRegister from './receivables-register.route'
import routeWebhook from './webhooks.route'
import routePhysicalWorld from './physical-world.route'
import routeSales from './sales'
import routeRecurrence from './recurrence'
import routeTeam from './team'
import routeSettings from './settings'
import routeKyc from './kyc.route'

const routeMain = store => ({
  path: '/:merchant_id/:account_id',
  component: () =>
    import(
      /* webpackChunkName: "motherboard" */ '@/components/project/templates/Motherboard.vue'
    ),
  beforeEnter: async (to, from, next) => {
    if (store.getters.maintenance) {
      next({ name: 'maintenance' })
      return
    }

    const { merchant_id: merchantId, account_id: accountId } = to.params
    const params = { merchant_id: merchantId, account_id: accountId }

    if (!merchantId?.includes('merch_') || !accountId?.includes('acc_')) {
      next({ name: 'default' })
      return
    }

    if (!store.getters.profile) {
      await store.dispatch('GET_AUTHORIZATION', params)
    }

    if (!store.getters.kyc) {
      await routeKyc({ store, params, next })
    }

    if (!store.getters.merchantSummary) {
      await store.dispatch('GET_MERCHANT_SUMMARY', merchantId)
    }

    await store.dispatch('GET_ACCOUNT_SUMMARY', params) // required shop.account:get

    /*
     * TODO:
     * Esse get deve ser feito dentro do created do componente SecretKeys.vue,
     * após todas as merchants com chaves depreciadas efetuarem o reset de chaves secretas.
     * O Monteiro ficou resposável por trazer o dado de quando não será mais necessário essa solução.
     * Data da implementação: 2024-07-24
     * Responsáveis: @Trocatti, @Pedro Henrique Lourenco Monteiro e @Léo Martins
     */
    await store.dispatch('GET_SECRET_KEY') // required deprecated keys

    next()
  },
  children: [
    () => ({
      path: '',
      name: 'motherboard',
      redirect: { name: 'home' }
    }),
    routeHome,
    routeSettings,
    routePhysicalWorld,
    ...routeSales,
    ...routeRecurrence,
    ...routeTeam,
    ...routeCustomer,
    ...routeRecipients,
    ...routeFinancial,
    ...routeContracts,
    ...routeReceivablesRegister,
    ...routeWebhook
  ].map(item => item(store))
})

export default routeMain
