import { MERCHANTS_BYPASS_LIST } from '../enums/secret-key-outdated.enum'

const KEYS_ACTIONS_ENUM = {
  accountId_get: 'developer.account_id:get',
  keyPublic_get: 'developer.key.public:get'
}

const SECRET_KEYS_ACTIONS_ENUM = {
  view: 'developer.key.secret:view',
  delete: 'developer.key.secret:delete',
  create: 'developer.key.secret:create'
}

const isPublicKeyEnabled = permissionIsEnabled => {
  return permissionIsEnabled(KEYS_ACTIONS_ENUM.keyPublic_get)
}

const isAccountIdEnabled = permissionIsEnabled => {
  return permissionIsEnabled(KEYS_ACTIONS_ENUM.accountId_get)
}

const isSecretKeyViewEnabled = permissionIsEnabled => {
  return permissionIsEnabled(SECRET_KEYS_ACTIONS_ENUM.view)
}

const isSecretKeyDeleteEnabled = (permissionIsEnabled, hasMFA) => {
  return hasMFA && permissionIsEnabled(SECRET_KEYS_ACTIONS_ENUM.delete)
}

const isSecretKeyCreateEnabled = (permissionIsEnabled, hasMFA, canCreate) => {
  return (
    canCreate && hasMFA && permissionIsEnabled(SECRET_KEYS_ACTIONS_ENUM.create)
  )
}

const isKeysEnabled = permissionIsEnabled => {
  return (
    isSecretKeyViewEnabled(permissionIsEnabled) &&
    isPublicKeyEnabled(permissionIsEnabled)
  )
}

const isSecretKeyOutdatedEnabled = (
  secretKeyList,
  merchantId,
  isTestAccount,
  isEmployee
) => {
  if (!merchantId) throw new Error('merchantId is required')

  if (secretKeyList?.length === 0) return false
  if (isTestAccount) return false
  if (isEmployee) return false
  if (MERCHANTS_BYPASS_LIST.includes(merchantId)) return false

  const OUTDATE_KEYS_DATE = new Date('2023-09-21')

  const hasOutdate = secretKeyList.some(
    sk => new Date(sk?.created_at) < OUTDATE_KEYS_DATE
  )

  return hasOutdate
}

export {
  isAccountIdEnabled,
  isPublicKeyEnabled,
  isSecretKeyViewEnabled,
  isSecretKeyDeleteEnabled,
  isSecretKeyCreateEnabled,
  isKeysEnabled,
  isSecretKeyOutdatedEnabled
}
