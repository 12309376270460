import { isSettingsPaymentsEnabled } from '@/actions-permission/settings-payments.permission'

const routePaymentMethods = store => ({
  path: 'payment-methods',
  name: 'payment-methods',
  component: () =>
    import(
      /* webpackChunkName: "paymentmethods" */ '@/views/Settings/PaymentMethods.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!isSettingsPaymentsEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.payment-methods'
    }
  }
})

export default routePaymentMethods
