import { isPsP } from '../enums/business-model.enum'

export const BALANCE_ACTIONS_ENUM = {
  view: 'recipients.balance:view'
}

export const SETTLEMENTS_ACTIONS_ENUM = {
  view: 'recipients.settlements:view'
}

const recipientsBalanceEnabledPsP = (
  permissionIsEnabledFn,
  componentIsEnabledFn,
  businessModel
) => {
  return (
    componentIsEnabledFn('recipients', 'balance') &&
    permissionIsEnabledFn(BALANCE_ACTIONS_ENUM.view) &&
    isPsP(businessModel)
  )
}

const recipientsBalanceEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    componentIsEnabledFn('recipients', 'balance') &&
    permissionIsEnabledFn(BALANCE_ACTIONS_ENUM.view)
  )
}

const recipientsSettlementsEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    componentIsEnabledFn('recipients', 'settlements') &&
    permissionIsEnabledFn(SETTLEMENTS_ACTIONS_ENUM.view)
  )
}

export {
  recipientsBalanceEnabled,
  recipientsBalanceEnabledPsP,
  recipientsSettlementsEnabled
}

export default recipientsBalanceEnabledPsP
