export const BUSINESS_MODEL_ENUM = {
  PSP_PHYS: 'p_s_p__phys',
  PSP: 'p_s_p',
  GATEWAY: 'gateway',
  HYBRID: 'gateway_and_psp'
}

export const isPsPOrHybrid = businessModel =>
  [BUSINESS_MODEL_ENUM.PSP, BUSINESS_MODEL_ENUM.HYBRID].includes(businessModel)

export const isGatewayOrHybrid = businessModel =>
  [BUSINESS_MODEL_ENUM.GATEWAY, BUSINESS_MODEL_ENUM.HYBRID].includes(
    businessModel
  )

export const isHybrid = businessModel =>
  businessModel === BUSINESS_MODEL_ENUM.HYBRID

export const isPhysical = businessModel =>
  businessModel === BUSINESS_MODEL_ENUM.PSP_PHYS

export const isPsP = businessModel =>
  [
    BUSINESS_MODEL_ENUM.PSP,
    BUSINESS_MODEL_ENUM.PSP_PHYS,
    BUSINESS_MODEL_ENUM.HYBRID
  ].includes(businessModel)

export const isGateway = businessModel =>
  businessModel === BUSINESS_MODEL_ENUM.GATEWAY

export default {
  BUSINESS_MODEL_ENUM,
  isPsP,
  isGateway,
  isHybrid,
  isPsPOrHybrid,
  isGatewayOrHybrid,
  isPhysical
}
