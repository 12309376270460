import buildUrl from '@/utils/buildUrl.utils'

const EXTERNAL_ENDPOINTS = {
  CDN_BRAND_IMAGE: {
    url: (brand = '') => {
      if (!brand) throw new Error('Missing brand name')

      return buildUrl({
        url: `${process.env.VUE_APP_CDN_URL}/assets/images/logos/brands/png`,
        pathParams: `${brand}.png`
      })
    }
  },
  CANNY_URL: {
    url: ({ merchantId, token = '' }) => {
      if (!merchantId) throw new Error('Missing merchant id')
      if (!token) throw new Error('Missing sso token')

      return buildUrl({
        url: 'https://dashspagarme.canny.io/feature-requests',
        queryString: {
          category: 'dash-pagarme-20',
          selectedCategory: 'dash-pagarme-20',
          companyID: merchantId,
          ssoToken: token
        }
      })
    }
  },
  SHOP: {
    subscription: {
      url: (merchantId, accountId) => {
        if (!merchantId) throw new Error('Missing merchantId')
        if (!accountId) throw new Error('Missing accountId')

        return buildUrl({
          url: process.env.VUE_APP_SHOP_URL,
          pathParams: `${merchantId}/${accountId}/subscription`
        })
      }
    },
    items: {
      url: (merchantId, accountId) => {
        if (!merchantId) throw new Error('Missing merchantId')
        if (!accountId) throw new Error('Missing accountId')

        return buildUrl({
          url: process.env.VUE_APP_SHOP_URL,
          pathParams: `${merchantId}/${accountId}/items`
        })
      }
    },
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_SHOP_URL,
        pathParams: `${merchantId}/${accountId}`
      })
    }
  },
  HUB: {
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_HUB_URL,
        queryString: {
          merchant_id: merchantId,
          account_id: accountId
        }
      })
    }
  },
  RISK: {
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_URL_RISK,
        pathParams: `${merchantId}/${accountId}`
      })
    }
  },
  COMPANY: {
    url: merchantId => {
      if (!merchantId) throw new Error('Missing merchantId')

      return buildUrl({
        url: process.env.VUE_APP_COMPANY_URL,
        pathParams: `${merchantId}`
      })
    }
  },
  HELP: {
    url: (pathParams = '') => {
      return buildUrl({
        url: process.env.VUE_APP_URL_HELP,
        pathParams: pathParams
      })
    }
  },
  SITE: {
    blog: {
      url: (pathParams = '') => {
        return buildUrl({
          url: `${process.env.VUE_APP_URL_SITE}blog`,
          pathParams: pathParams
        })
      }
    },
    url: (pathParams = '') => {
      return buildUrl({
        url: process.env.VUE_APP_URL_SITE,
        pathParams: pathParams
      })
    }
  },
  DOCUMENTATION: {
    url: (pathParams = '') => {
      return buildUrl({
        url: `${process.env.VUE_APP_URL_DOCUMENTATION}docs`,
        pathParams: pathParams
      })
    }
  },
  CHARGEBACK_RECOMMENDATION_DOC: {
    url: () => {
      return buildUrl({
        url: `${process.env.VUE_APP_URL_CHARGEBACK_RECOMMENDATION_DOC}`
      })
    }
  }
}

export { EXTERNAL_ENDPOINTS }
